import React, { MouseEvent, useEffect, useState } from "react";
import Layout from "layouts/EmptyLayout";
import Loading from "components/Common/Loading";
import { useMqtt } from "libs/api";
import config from "config/news";

const BGM_PPLAYERS = [
  {
    id: "1",
    name: "Living Player",
  },
  {
    id: "cTFeAPgdykkr",
    name: "Working Room Player",
  },
];

const PLAYER_OPTIONS = {
  idPrefix: "bgm-player-",
  topicPrefix: "thangiswho/",
  actions: [
    {
      name: "Play/pause ♫",
      action: "play",
    },
    {
      name: "Next song ›",
      action: "cmd-mode-AT+CC",
    },
    {
      name: "Next folder ▶︎",
      action: "cmd-mode-AT+AA06",
    },
    {
      name: "Volume down -",
      action: "cmd-mode-AT+CF",
    },
    {
      name: "Volume up +",
      action: "cmd-mode-AT+CE",
    },
    {
      name: "Stop ◻︎",
      action: "stop",
    },
    {
      name: "Reset volume",
      action: "cmd-mode-AT+CA03",
    },
    {
      name: "Bluetooth Mode",
      action: "ble-mode",
    },
    {
      name: "TF Card Mode",
      action: "tf-mode",
    },
    {
      name: "Sound Card Mode",
      action: "cmd-mode-AT+CM07",
    },
  ],
};

export default function Music() {
  const topicPrefix = PLAYER_OPTIONS.topicPrefix + PLAYER_OPTIONS.idPrefix;
  const { mqttPublish, isLoading, status } = useMqtt(config.mqtt.options);

  return (
    <>
      <Layout title="BGM Player">
        {isLoading && (
          <Loading className="fixed h-32 w-full top-0 mx-auto">
            {status}
          </Loading>
        )}
        <h1 className="w-full mt-8 p-4 h-full text-xl">
          BGM Players <span className="text-xs">({status})</span>
        </h1>

        {BGM_PPLAYERS.map((player, pi) => (
          <div className="w-full mt-8 px-4 h-full" key={pi}>
            <h2 className="w-full p-4 text-lg">{player.name}</h2>
            <div className="w-full flex flex-wrap">
              {PLAYER_OPTIONS.actions.map((cmd, i) => (
                <div
                  className="flex flex-col w-6/12 md:w-3/12 lg:w-2/12 xl:w-1/12 p-3"
                  key={i}
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      mqttPublish(topicPrefix + player.id, cmd.action);
                      e.preventDefault();
                    }}
                    className="w-full align-middle object-center rounded-lg p-4 bg-blue-700 hover:bg-orange-600 text-gray-300 hover:text-gray-300"
                  >
                    <div className="w-full mx-auto">{cmd.name}</div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Layout>
    </>
  );
}

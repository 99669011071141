import React, { ReactNode } from "react";

type Props = {
  className?: string;
  children: ReactNode;
};

export default function Loading({ className, children }: Props) {
  return (
    <>
      <div
        className={
          "animate-pulse absolute w-full px-2 h-6 bg-background-300 text-foreground-300 " +
          className
        }
      >
        <div className="w-full h-6">{children}</div>
      </div>
    </>
  );
}

import React from "react";
import Link from "components/Common/Link";
import Brand from "components/Common/Brand";

type Props = {
  className?: string;
};
export default function Navbar({ className }: Props) {
  return (
    <>
      <nav className={`z-50 sticky shadow font-medium text-sm ${className}`}>
        <div className="pl-3 pr-1 py-3">
          <div className="md:flex md:items-center md:justify-between">
            <div className="flex flex-initial items-center justify-between">
              <div className="text-left text-sm uppercase  font-semibold">
                <Link href="/" className="py-2 sm:py-1">
                  <Brand className="h-6 brand fill-current" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
